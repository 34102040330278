<template>
    <div class="row mr-0" style="margin-top: 10%;margin-bottom: 8%">
        <div class="col-xl-4 m-auto ">
            <div class="card">
                <div class="card-header bg-info">
                    <h1 class="text-center text-white">Gestion Stock PrettyLinge</h1>

                </div>
                <div class="card-body">
                    <form ref="form" @submit.prevent>

                        <b-form-group label="Username"   >
                            <b-form-input  type="text" v-model="user.username" required></b-form-input>
                        </b-form-group>
                        <b-form-group label="Password" label-for="name-input" >
                            <b-form-input id="name-input" type="password" v-model="user.password"  required></b-form-input>
                        </b-form-group>
                       <input type="submit"  size="lg" class="btn btn-info w-100 mt-2" value="Login" @click="login"/>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

        export default {
        name: "login",
            data() {
                return {
                    user: {
                        username: '',
                        password: '',
                    }

                }
            },

       methods:{
           // auth(){
           //     this.$http
           //         .get('me')
           //         .then(response => {
           //             Vue.prototype.$is_admin = response.data.user.is_admin
           //         })
           //         .catch(error => {
           //             console.log(error)
           //         })
           // },
            login(){
                 this.$http
                .post('login',this.user)
                .then(response => {
                    console.log(response.data.token)
                    const token = response.data.token;
                    localStorage.setItem('token', token)
                    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token
                    // this.auth()
                    console.log("apres",Vue.prototype.$is_admin)
                    this.$router.push('/factures')
                })
                .catch(error => {
                  console.log(error)
                    this.$alertify.error("Le mot de passe / l'email est incorrect");
         })
            }
       }

    }
</script>

<style scoped>


</style>